<template>
  <div class="text-left" style="height: 100vh">
    <div class="row" style="height: 100%">
      <!-- Left -->
      <div class="left_menu col-sm-4 pr-0" style="heigth: 100%">
        <!-- Add  -->
        <div
          :class="showAddAttendees==0 ? 'select item_list pl-3 pr-3' : 'item_list pl-3 pr-3'"
          style="border-bottom: 1px solid #e5e5e5; border-right: none"
          @click="showContentMenuAddUserInEvent()"
        >
          <div class="pt-3 pb-3">
            <div class="sub_item_icon">
              <i class="fas fa-user-plus" style="color:#3F51B5; font-size: 20px;"></i>
            </div>
            <div class="sub_item_title">{{ $t('attendance.addAttendees') }}</div>
          </div>
        </div>

        <!-- Attendant -->
        <div
          :class="showAddAttendees==1 && attendanceStatus == null ? 'select item_list pl-3 pr-3' : 'item_list pl-3 pr-3'"
          style="border-bottom: 1px solid #e5e5e5; border-right: none"
          @click="showContentMenuUserInEvent()"
        >
          <div class="pt-3 pb-3">
            <div class="sub_item_icon">
              <i class="fas fa-user-friends" style="color:#3F51B5; font-size: 20px;"></i>
            </div>
            <div class="sub_item_title">{{ $t('attendance.attendanceList') }}</div>
            <div class="sub_item_amount">{{attendanceNumber.total}}</div>
          </div>
        </div>

        <!--  -->
        <div :class="showAddAttendees==1 && attendanceStatus == 1 ? 'select item_list pt-3 pb-3' : 'item_list pt-3 pb-3'" 
          @click="getUserWithAttendanceStatus(1)"
        >
          <div class="sub_item_icon" style="padding-left: 35px">
            <i class="fas fa-check-circle" style="color: green;"></i>
          </div>
          <div class="sub_item_title">{{ $t('attendance.title') }}</div>
          <div class="sub_item_amount">{{attendanceNumber.attendance}}</div>
        </div>
        <!-- Absence -->
        <div :class="showAddAttendees==1 && attendanceStatus == 2 ? 'select item_list pt-3 pb-3' : 'item_list pt-3 pb-3'"
          @click="getUserWithAttendanceStatus(2)"
        >
          <div class="sub_item_icon" style="padding-left: 35px">
            <i class="fas fa-times-circle" style="color: red;"></i>
          </div>
          <div class="sub_item_title">{{ $t('attendance.absencesNotice') }}</div>
          <div class="sub_item_amount">{{attendanceNumber.absence}}</div>
        </div>
        <!--  -->
        <div :class="showAddAttendees==1 && attendanceStatus == 0 ? 'select item_list pt-3 pb-3' : 'item_list pt-3 pb-3'"
          @click="getUserWithAttendanceStatus(0)"
        >
          <div class="sub_item_icon" style="padding-left: 35px">
            <i class="far fa-circle" style="color: green;"></i>
          </div>
          <div class="sub_item_title">{{ $t('attendance.noAttendance') }}</div>
          <div class="sub_item_amount">{{attendanceNumber.no_attendance}}</div>
        </div>
        <!--  -->
        <!--  -->
        <div
          :class="showAddAttendees==2 ? 'select item_list pl-3 pr-3' : 'item_list pl-3 pr-3'"
          style="border-bottom: 1px solid #e5e5e5; border-right: none; border-top: 1px solid #e5e5e5"
          @click="showGroup()"
        >
          <div class="pt-3 pb-3">
            <div class="sub_item_icon">
              <i class="fas fa-users" style="color:#3F51B5; font-size: 20px;"></i>
            </div>
            <div class="sub_item_title">{{ $t('attendance.group.addGroup') }}</div>
          </div>
        </div>
        <!--  -->
      </div>

      <!-- Right -->
      <div class="col-sm-8" style="background: #ECEFF0">
        <!-- content add attendee-->
        <div v-if="showAddAttendees==0" id="addAttendees">
          <!-- add Attendant -->
          <!-- item -->
          <div class="row pr-3">
            <!-- <div class="col-sm-2 mt-3 pr-0" style="top: 18px">
              <div class style="display: inline;">{{ $t('attendance.search') }}</div>
            </div> -->
            <div class="col-sm-12 mt-3">
              <div class="pb-4 m-2">
                <input
                  v-model="search.fullname"
                  v-on:keyup.enter="onSearchUserNotInEvent()"
                  class="search_input form-control form-control-lg"
                  type="text"
                  style="font-size: 14px;"
                  :placeholder="$t('attendance.addAttendees')"
                />
              </div>
              <table class="table table-borderless" id="table_attendees_content">
                <tbody>
                  <tr
                    v-for="(userNotEvent, index) in userNotInEvent"
                    :key="userNotEvent + index"
                    style="vertical-align:middle;"
                  >
                    <div class="m-1" style="background: #FFFFFF">
                      <td>
                        <div
                          v-if="userNotEvent.avatar"
                        >
                          <!-- <div class="circular--portrait">
                            <img :src="userNotEvent.avatar" alt="Avatar" class="circular--square" />
                          </div> -->
                          <avatar
                            :src="userNotEvent.avatar"
                            backgroundColor="#e5e5e5"
                            color="#0097A7"
                            :size="30"
                          ></avatar>
                        </div>
                        <avatar v-else
                          :username="userNotEvent.name"
                          backgroundColor="#e5e5e5"
                          color="#0097A7"
                          :size="30"
                        ></avatar>
                      </td>
                      <td>
                        <div style="vertical-align:middle;">
                          <p class="m-0" style="font-size: 14px; color: #000000">{{userNotEvent.name}}</p>
                          <p class="m-0" style="font-size: 12px; color: #707070">
                            {{getCompanyAndPosition(userNotEvent.company, userNotEvent.position)}}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="" style="height: 100%">
                          <button
                            type="button"
                            class="btn mr-3"
                            style="color:#26BA50;  border: 1px solid #26BA50; font-size: 14px !important;"
                            @click="addUserIntoMeeting(userNotEvent)"
                          >{{$t('attendance.addToMeeting')}}</button>
                        </div>
                      </td>
                    </div>
                  </tr>
                </tbody>
              </table>
              <pagination :rows="userNotInEventPaginate.total" :per-page="userNotInEventPaginate.perPage" :current-page="userNotInEventPaginate.currentPage" @onPageChange="onUserNotInEventPageChange"></pagination>
            </div>
          </div>
          <!-- end item -->
        </div>
        <!-- ###################################################### -->
        <div v-if="showAddAttendees==1">
          <!-- item -->
          <div class="row p-4">
            <!-- <div class="col-sm-11 mt-3"> -->
            <table
              class="table table-borderless"
              v-if="attendanceList.length > 0"
              id="table_attendees_content"
            >
              <tbody>
                <tr
                  v-for="(attendee, index) in attendanceList"
                  :key="attendee + index"
                  style="vertical-align:middle;"
                >
                  <div
                    class="m-1"
                    :style="attendee.attendance_status==0 ? 'background: #FFFFFF; cursor: pointer' : 'background: #FFFFFF'"
                    @click="attendee.attendance_status==0 ? changeAttendanceStatus(attendee): ''"
                  >
                    <td>
                      <div
                        v-if="attendee.avatar"
                      >
                        <!-- <div class="circular--portrait">
                          <img :src="attendee.avatar" alt="Avatar" class="circular--square" />
                        </div> -->
                        <avatar
                          :src="attendee.avatar"
                          backgroundColor="#e5e5e5"
                          color="#0097A7"
                          :size="30"
                        ></avatar>
                      </div>
                      <avatar v-else
                        :username="attendee.name"
                        backgroundColor="#e5e5e5"
                        color="#0097A7"
                        :size="30"
                      ></avatar>
                    </td>
                    <td>
                      <div style="vertical-align:middle;">
                        <p class="m-0" 
                          :style="`font-size: 14px; 
                            ${attendee.joinEvent === joinMeetingEnum.JOIN_MEETING ? 'color: #006ad4; font-weight: bold' : 'color: #000000'}`">
                          {{attendee.name}}
                        </p>
                        <p class="m-0" style="font-size: 12px; color: #707070">
                          {{getCompanyAndPosition(attendee.company ,attendee.position)}}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div v-show="attendee.attendance_status==1">
                        <i class="fas fa-check-circle" style="color: green;"></i>
                      </div>
                      <div v-show="attendee.attendance_status==2">
                        <i class="fas fa-times-circle" style="color: red;"></i>
                      </div>
                      <div v-show="attendee.attendance_status==0">
                        <i class="far fa-circle" style="color: green;"></i>
                      </div>
                    </td>
                    <td style="font-size: 13px;">
                      <span v-if="attendee.seat_code">{{$t('stated.seat')}}: </span><span>{{attendee.seat_code}}</span>
                    </td>
                    <td>
                      <div 
                        class="tooltips"
                        style="font-size: 14px; color: #707070"
                      >
                        {{attendee.attendance_status==0 ? $t('attendance.noAttendance')
                        : attendee.attendance_status==1 ? $t('attendance.attendedAttendance')
                        : spliceString(attendee.reason_absence, 25) }}
                        
                        <div v-if="attendee.attendance_status==2" class="tooltiptext">
                          {{attendee.reason_absence}}
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              </tbody>
            </table>
            <!-- </div> -->
            <div v-if="attendanceList.length===0" class="col-12 p-0" style="text-align: center; font-size: 20px;">
              <span>{{$t('attendance.emptyListMessage')}}</span>
            </div>
          </div>
          <!-- end item -->
          <pagination v-if="attendanceList.length > 0" :rows="attendancePaginate.total" :per-page="attendancePaginate.perPage" :current-page="attendancePaginate.currentPage" @onPageChange="onAttendancePageChange"></pagination>
        </div>
        <div>
          <management-group 
            ref="ManagementGroup" 
            :pageParams="userNotInEventPaginate.currentPage"></management-group>
        </div>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Avatar from "vue-avatar";
import functionUtils from 'utils/functionUtils';
import moment from "moment";
import Enum from 'enum/enum'
import ManagementGroup from './ManagementGroup.vue'
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
// import XLSX from "xlsx";

export default {
  components: {
    avatar: Avatar,
    'management-group': ManagementGroup
  },
  data: () => ({
    isLoading: false,
    attendanceList: [],
    event_id: null,
    showAddAttendees: 1,
    userNotInEvent: [],
    attendancePaginate: {
      total: 1,
      perPage: 1,
      currentPage: 1
    },
    userNotInEventPaginate: {
      total: 1,
      perPage: 1,
      currentPage: 1
    },
    search: {
      fullname: null
    },
    attendanceNumber:{
      total: 0,
      attendance: 0,
      no_attendance: 0,
      absence: 0
    },
    attendanceStatus: null,
    event:{
      name: ''
    },
    joinMeetingEnum: Enum.JoinMeetingEnum
  }),
  watch: {
    GET_USER_IN_EVENT_LIST_DATA() {
      this.attendanceList = [];
      // Paginate
      this.attendancePaginate.total = this.GET_USER_IN_EVENT_LIST_DATA.paginate.totalPage
      this.attendancePaginate.perPage = 1
      this.attendancePaginate.currentPage = this.GET_USER_IN_EVENT_LIST_DATA.paginate.currentPage
      // Handle data
      this.GET_USER_IN_EVENT_LIST_DATA.results.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let attendee_position = data.company_position;
        let attendance_status = data.user_in_event.attendance;
        let item = {
          id: data.id,
          name: full_name,
          position: attendee_position,
          company: data.company_name,
          avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          attendance_status: attendance_status,
          user_in_event_id: data.user_in_event.id,
          reason_absence: data.user_in_event.reason_absence,
          seat_code: data.seats.seat_code,
          joinEvent: data.user_in_event.join_event
        };
        this.attendanceList.push(item);
      });
    },
    GET_USER_NOT_IN_EVENT_DATA() {
      // Paginate
      this.userNotInEventPaginate.total = this.GET_USER_NOT_IN_EVENT_DATA.paginate.totalPage
      this.userNotInEventPaginate.perPage = 1
      this.userNotInEventPaginate.currentPage = this.GET_USER_NOT_IN_EVENT_DATA.paginate.currentPage
      // Handle data
      this.userNotInEvent = []
      this.GET_USER_NOT_IN_EVENT_DATA.results.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let attendee_position = data.company_position;
        let item = {
          id: data.id,
          name: full_name,
          position: attendee_position,
          company: data.company_name,
          avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          is_group: data.is_group
        };
        this.userNotInEvent.push(item);
      });
      this.isLoading = false
    },
    GET_ATTENDANCE_NUMBER_DATA(){
      this.attendanceNumber.total = this.GET_ATTENDANCE_NUMBER_DATA.total;
      this.attendanceNumber.attendance = this.GET_ATTENDANCE_NUMBER_DATA.attendance;
      this.attendanceNumber.no_attendance = this.GET_ATTENDANCE_NUMBER_DATA.no_attendance;
      this.attendanceNumber.absence = this.GET_ATTENDANCE_NUMBER_DATA.absence;
    },
    EVENT_DETAIL_DATA(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    },
    CALLBACK_API_ATTENDANCE: function () {
      if (this.$route.name === 'information.title' || this.$route.name === 'attendance.title'){
        this.onAttendancePageChange(this.attendancePaginate.currentPage)
      }
      let filter = {
        params: { 
          event: sessionStorage.getItem("event_id"),
          getAllUserInGroup: true
        }
      }
      this.GET_ATTENDANCE_NUMBER(filter)
    }
  },
  computed: {
    ...mapGetters([
      "GET_SNACK_BAR",
      "GET_ATTENDEE_DATA",
      "GET_SPEAKER_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "GET_USER_NOT_IN_EVENT_DATA",
      "GET_ATTENDANCE_NUMBER_DATA",
      "EVENT_DETAIL_DATA",
      "CALLBACK_API_ATTENDANCE"
    ])
  },
  methods: {
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_ATTENDEE_LIST",
      "GET_SPEAKER_LIST",
      "GET_USER_IN_EVENT_LIST",
      "GET_USER_NOT_IN_EVENT",
      "UPDATE_ATTENDANCE_STATUS",
      "ADD_USER_INTO_MEETING",
      "GET_ATTENDANCE_NUMBER",
      "GET_EVENT_DETAIL",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ]),
    /**
     * Export attendee list to excel
     */
    spliceString(content,length){
      return functionUtils.spliceString(content, length)
    },
    getCompanyAndPosition: function(company, position){
      return functionUtils.getCompanyAndPosition(company, position)
    },
    /**
     * Search user not in event
     */
    onSearchUserNotInEvent() {
      this.isLoading = true
      const user_not_in_event_data = {
        params: {
          page: 1,
          event: this.event_id,
          fullname: this.search.fullname,
          getGroup: true
        }
      };
      this.GET_USER_NOT_IN_EVENT(user_not_in_event_data).then(
        function() {
          // Todo
        }.bind(this)
      ).catch(
        function() {
          this.isLoading = false
        }.bind(this)
      )
    },
    showContentMenuUserInEvent() {
      this.showAddAttendees = 1;
      this.attendanceStatus = null
      const customer_data = {
        params: {
          page: 1,
          event: this.event_id,
          getAllUserInGroup: true
        }
      };
      this.GET_USER_IN_EVENT_LIST(customer_data);
    },
    changeAttendanceStatus(item) {
      item['event'] = this.event_id;
      this.sendNotification(item)
      this.UPDATE_ATTENDANCE_STATUS(item)
        .then(
          function() {
            const customer_data = {
              params: {
                page: this.attendancePaginate.currentPage,
                event: this.event_id,
                attendanceStatus: this.attendanceStatus,
                getAllUserInGroup: true
              }
            };
            this.GET_USER_IN_EVENT_LIST(customer_data);
            this.GET_ATTENDANCE_NUMBER(customer_data);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success"
            });
          }.bind(this)
        )
        .catch(
          function(error) {
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger"
            });
          }.bind(this)
        );
    },
    /**
     * Attendance page change
     */
    onAttendancePageChange: function (page) {
      this.attendancePaginate.currentPage = page
      const customer_data = {
        params: {
          page: this.attendancePaginate.currentPage,
          event: this.event_id,
          attendanceStatus: this.attendanceStatus,
          getAllUserInGroup: true
        }
      }
      this.GET_USER_IN_EVENT_LIST(customer_data);
    },
    /**
     * User not in event page change
     */
    onUserNotInEventPageChange: function (page) {
      this.userNotInEventPaginate.currentPage = page
      const customer_data = {
        params: {
          page: this.userNotInEventPaginate.currentPage,
          event: this.event_id,
          fullname: this.search.fullname,
          getGroup: true
        }
      }
      this.GET_USER_NOT_IN_EVENT(customer_data);
    },
    addUserIntoMeeting: function(userData){
      let nowTime = new Date(Date.now());
      let nowDate = moment(nowTime)
        .utc()
        .format("YYYY-MM-DD");
      let data={
        user_id: userData.id,
        event_id: this.event_id,
        privilege_id: 4,
        now_date: nowDate,
        is_group: userData.is_group
      }
      if (this.isLoading==false){
        this.ADD_USER_INTO_MEETING(data)
          .then(
            function() {
              this.isLoading=true;
              const user_not_in_event_data = {
                params: {
                  page: this.userNotInEventPaginate.currentPage,
                  event: this.event_id,
                  fullname: this.search.fullname,
                  getAllUserInGroup: true,
                  getGroup: true
                }
              };
              this.GET_USER_NOT_IN_EVENT(user_not_in_event_data);
              this.GET_ATTENDANCE_NUMBER(user_not_in_event_data);
              this.GET_USER_IN_EVENT_LIST(user_not_in_event_data);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
              this.sendNotificationAddUserIntoMeeting(userData.id)
            }.bind(this)
          )
          .catch(
            function(error) {
              let mess_error = error.response.data.message;
              if (mess_error=='the_event_has_ended'){
                mess_error = this.$t('common.eventHasEnded')
              }
              this.ON_SHOW_TOAST({
                message: mess_error,
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    sendNotificationAddUserIntoMeeting: function(user_id) {
      let data = {
        event_id: sessionStorage.getItem("event_id"),
        send_chairmain_and_secretary: true,
        user_id: user_id,
        action_code: TemplateCategoriesEnum.ADD_USER_IN_MEETING,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.AddUserInMeeting,
        privilege_name: this.$t('attendance.privilegeName.attendee')
      };
      this.SEND_NOTIFICATION_IN_MEETING(data);
    },
    
    showContentMenuAddUserInEvent(){
      this.showAddAttendees = 0
    },
    getUserWithAttendanceStatus(index){
      this.attendanceStatus=index;
      this.showAddAttendees = 1;
      const customer_data = {
        params: {
          page: 1,
          event: this.event_id,
          attendanceStatus: this.attendanceStatus,
          getAllUserInGroup: true
        }
      };
      this.GET_USER_IN_EVENT_LIST(customer_data);
    },
    sendNotification(userData){
      let data = {
        event_id: this.event_id,
        // message_title: this.event.name,
        // message_body: this.$t('notification.attendance.attendanceNotification', {'0': userData.name}),
        user_id: userData.id,
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.ATTENDANCE,
        template_type: Enum.TemplateType.Notification,
        full_name: userData.name,
        notification_type_code: Enum.NotificationType.Attendance
      }

      // let smsContent = this.$t('notification.attendance.attendanceNotification', {'0': userData.name})

      let smsData = {
        event_id: this.event_id,
        // content: functionUtils.uniDecodeString(smsContent),
        full_name: userData.name,
        user_id: userData.id,
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.ATTENDANCE,
        template_type: Enum.TemplateType.SMS
      }

      if (userData.attendance_status==0){
        this.SEND_NOTIFICATION_IN_MEETING(data)
        this.SEND_SMS_IN_MEETING(smsData)
        this.sendEmail(userData)
      }
    },
    sendEmail: function(userData){
      let data = {
        action_code: TemplateCategoriesEnum.ATTENDANCE,
        template_type: Enum.TemplateType.Email,
        event_id: this.event_id,
        full_name: userData.name,
        user_id: userData.id,
        send_chairmain_and_secretary: true,
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    // Socket
    webSocketUserInEvent: function(){
      const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws"
      const backEndUrl = new URL(process.env.VUE_APP_BACKEND_URL)
      const socket = new WebSocket(
        ws_scheme +
        '://'
        + backEndUrl.host
        + `/${Enum.ChannelsActionCodeEnum.UserInEvent}/`
      )
      socket.onmessage = function (event)  {
        if (event.data != undefined){
          let message_data = JSON.parse(event.data)
          if (message_data.message == Enum.ActionEnum.UpdateAttendanceStatus){
            const customer_data = {
              params: {
                page: this.attendancePaginate.currentPage,
                event: this.event_id,
                fullname: this.search.fullname,
                getAllUserInGroup: true,
                attendanceStatus: this.attendanceStatus,
              }
            };
            this.GET_USER_IN_EVENT_LIST(customer_data);
            this.GET_ATTENDANCE_NUMBER(customer_data)
          }
        }
      }.bind(this)

      socket.onclose = function() {
        // this.connected = false
      }
    },
    webSocketUserNotInEvent: function(){
      const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws"
      const backEndUrl = new URL(process.env.VUE_APP_BACKEND_URL)
      const socket = new WebSocket(
        ws_scheme +
        '://'
        + backEndUrl.host
        + `/${Enum.ChannelsActionCodeEnum.UserNotInEvent}/`
      )

      socket.onmessage = function (event)  {
        if (event.data != undefined){
          let message_data = JSON.parse(event.data)
          if (message_data.message == Enum.ActionEnum.AddUserInEvent){
            const user_not_in_event_data = {
              params: {
                page: this.userNotInEventPaginate.currentPage,
                event: this.event_id,
                fullname: this.search.fullname,
                getAllUserInGroup: true,
                getGroup: true
              }
            };
            this.GET_USER_NOT_IN_EVENT(user_not_in_event_data);
            this.GET_ATTENDANCE_NUMBER(user_not_in_event_data);
            this.GET_USER_IN_EVENT_LIST(user_not_in_event_data);
          }
        }
      }.bind(this)

      socket.onclose = function() {
        // this.connected = false
      }
    },
    showGroup: function(){
      this.$refs.ManagementGroup.showModal()
    }
  },
  async mounted() {
    // sockets
    this.webSocketUserInEvent()
    this.webSocketUserNotInEvent()
    // 
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    const customer_data = {
      params: {
        page: 1,
        event: event_id,
        fullname: this.search.fullname,
        getAllUserInGroup: true,
        getGroup: true
      }
    };
    this.GET_USER_NOT_IN_EVENT(customer_data);
    this.GET_USER_IN_EVENT_LIST(customer_data);
    this.GET_ATTENDANCE_NUMBER(customer_data)
    let event = {
      params:{
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event);
  },
  created(){
     
  }
};
</script>
<style scoped lang="scss">
@import "../../style/color.scss";
.info {
  padding: 10px;
}
.left_menu {
  border-right: 1px solid #e5e5e5e5;
}
table#table_attendees_content td:nth-child(1) {
  text-align: center !important;
  width: 1% !important;
  white-space: nowrap !important;
}
table#table_attendees_content td:nth-child(3) {
  text-align: center;
  width: 1%;
  white-space: nowrap;
}
table#table_attendees_content td:nth-child(4) {
  text-align: center;
  width: 23%;
  white-space: nowrap;
}
table#table_attendees_content td:nth-child(5) {
  width: 200px;
}
td {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
tr td:last-child {
  width: 1%;
  white-space: nowrap;
}
.item_list {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  // display: flex;
}
.sub_item_icon {
  display: inline;
  padding-right: 15px;
  align-self: center;
}
.sub_item_title {
  display: inline;
  align-self: center;
}
.sub_item_amount {
  display: inline;
  float: right;
  align-self: center;
}
.search_input {
  width: 100%;
}
/*  */
/*  */
/deep/ .card-header {
  background-color: transparent !important;
  border: none;
}
/deep/ .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: $primary;
  border: 1px solid #000;
}
.select {
  background: #ECEFF0;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.item_list:hover{
  background: #ECEFF0;
}

.tooltips {
  position: relative;
  display: inline-block;
}

.tooltips .tooltiptext {
  visibility: hidden;
  // width: 120px;
  background-color: #BDBDBD;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
  // top: 150%;
  // left: 50%;
  // margin-left: -60px;

  padding-left: 10px;
  padding-right: 10px;
}

.tooltips .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #BDBDBD;
}
.tooltips:hover .tooltiptext {
  visibility: visible;
}
.button-meeting-style {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  color: #ffffff;
}
</style>
